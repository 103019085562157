import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import { Props } from './types';

const ProductComponent = ({ data }: Props) => {
    const adaptedLexaData = adaptLexaData(data);
    if (!adaptedLexaData) {
        return null;
    }
    return <View {...adaptedLexaData} />;
};

export default ProductComponent;
