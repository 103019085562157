/* @flow */
import React from 'react';
import adaptData from './adaptLexaData';
import View from './View';
import type { GetResidentialListing } from '../../../../models/lexa';

function Address(props: { data: GetResidentialListing }) {
    const adaptedData = adaptData(props.data);
    return <View {...adaptedData} />;
}

export default Address;
