/* @flow */

import { type SlideName, SlideNames } from '../../media-viewer';
import type { MediaIconClickType } from '../../../client/tracking/event/types';
import type { LexaImage } from '../../../models/lexa';
import type { Thumbnail } from '../types';
import createThumbnail from './createThumbnail';

const createFloorplanThumbnail = (
    openMediaViewer: (name: SlideName) => void,
    mediaViewerIconClick: (type: MediaIconClickType) => void,
    floorplans: Array<LexaImage>
): Array<Thumbnail> => {
    return floorplans.slice(0, 1).map((floorplan) =>
        createThumbnail({
            image: floorplan,
            action: () => {
                openMediaViewer(SlideNames.FLOORPLAN);
                mediaViewerIconClick('floorplan');
            },
            label: 'floorplans available',
            altText: 'floorplan',
        })
    );
};

export default createFloorplanThumbnail;
