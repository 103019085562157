/* @flow */
import type { Channel } from '../../../../models/Channel';
import type { ResidentialListing } from '../../../../models/lexa';
import formatAddress from './formatAddress';
import formatPropertyType from './formatPropertyType';
import formatMedia from './formatMedia';
import formatLandSize from './formatLandSize';

const commonDescriptionFields = ({
    propertyType,
    generalFeatures,
    propertySizes,
    address,
    media: { images },
}: ResidentialListing) => {
    const features = generalFeatures ? `${generalFeatures.bedrooms.value} bedroom ` : formatLandSize(propertySizes);
    const type = formatPropertyType(propertyType);
    const formattedPropertyType = features ? `${features}${type.toLowerCase()}` : type;
    const location = formatAddress(address);
    const mediaInfo = formatMedia(images);
    return { formattedPropertyType, location, mediaInfo };
};

const buyDescription = (listing: ResidentialListing) => {
    const { formattedPropertyType, location, mediaInfo } = commonDescriptionFields(listing);
    const extraDescription = `View ${mediaInfo}, floor plans and ${listing.address.suburb} suburb information.`;
    const propertyDescription = `${formattedPropertyType} for sale ${location}, ${listing.price.display}.`;
    return `${propertyDescription} ${extraDescription}`;
};

const rentDescription = (listing: ResidentialListing) => {
    const { formattedPropertyType, location, mediaInfo } = commonDescriptionFields(listing);
    const extraDescription = `View ${mediaInfo}, features and ${listing.address.suburb} suburb information.`;
    const propertyDescription = `${formattedPropertyType} for rent ${location}, ${listing.price.display}.`;
    return `${propertyDescription} ${extraDescription}`;
};

const soldDescription = ({
    id,
    address: {
        display: { fullAddress, shortAddress },
        suburb,
        state,
        postcode,
    },
}: ResidentialListing): string => {
    const propertyAddress = `${
        shortAddress || `property ${id}`
    } & other properties in ${suburb}, ${state} ${postcode}.`;
    return `${fullAddress || `Property ${id}`}. View property details and sold price of ${propertyAddress}`;
};

const descriptionTemplate = {
    buy: buyDescription,
    sold: soldDescription,
    rent: rentDescription,
};

export default (channel: Channel) => (listing: ResidentialListing) => {
    return {
        name: 'description',
        content: descriptionTemplate[channel](listing),
    };
};
