/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type {
    GetResidentialListing,
    ResidentialListing,
    ListingCompany,
    ResidentialListingViewConfiguration,
} from '../../../models/lexa';
import type { AdaptedProps } from '.';
import type { Channel } from '../../../models/Channel';
import ListingCompanyHeaderBranding from './ListingCompanyHeaderBranding.graphql';

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
    listingCompany: ?ListingCompany,
};

const adapter =
    (channel: Channel) =>
    ({ viewConfiguration, listingCompany }: RequiredProps): ?AdaptedProps => {
        const HEADER_BRANDING_CID = `agency-profile-page|${channel}:pdp:header-logo&sourcePage=${channel}:property-details&sourceElement=agency-branding`;

        if (viewConfiguration.details.branding.header.size === 'LARGE' && listingCompany) {
            return {
                backgroundColor: listingCompany.branding.primaryColour,
                templatedUrl: listingCompany.media.logo?.templatedUrl,
                altText: listingCompany.name,
                link: listingCompany._links.canonical.href.replace('{cid}', HEADER_BRANDING_CID),
            };
        } else {
            return null;
        }
    };

export default (channel: Channel) =>
    compose<GetResidentialListing, ResidentialListing, RequiredProps, ?AdaptedProps>(
        adapter(channel),
        getFragmentData(ListingCompanyHeaderBranding),
        (data) => data.getListing()
    );
