/* @flow */

import { type SlideName, SlideNames } from '../../media-viewer';
import type { LexaImage } from '../../../models/lexa';
import { DEFAULT_DISPLAY_ADDRESS } from '../../../models/Address';
import type { Address } from '../../../models/lexa';
import type { Thumbnail } from '../types';
import createThumbnail from './createThumbnail';

const getAddress = (address: Address) => address.display.fullAddress || DEFAULT_DISPLAY_ADDRESS;

const createImageThumbnails = (
    openMediaViewer: (name: SlideName) => void,
    propertyImages: Array<LexaImage>,
    address: Address
): Array<Thumbnail> => {
    const threeThumbnails = propertyImages.slice(0, 3);

    return threeThumbnails.map((image, index) => {
        let slideName;
        if (index === 0) {
            slideName = SlideNames.SECOND;
        } else if (index === 1) {
            slideName = SlideNames.THIRD;
        } else if (index === 2) {
            slideName = SlideNames.FOURTH;
        }
        return createThumbnail({
            image: image,
            action: () => openMediaViewer(slideName),
            label: getAddress(address),
            altText: getAddress(address),
        });
    });
};

export default createImageThumbnails;
