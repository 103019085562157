/* @flow */
import type { LexaPropertyType } from '../../../../models/lexa/propertyType';

const propertyTypeIds = ['rural-other', 'residential-other'];
const formatPropertyType = ({ id, display }: LexaPropertyType): string => {
    const isOther = propertyTypeIds.findIndex((propertyTypeId) => propertyTypeId === id) !== -1;
    return isOther ? 'Property' : display;
};

export default formatPropertyType;
