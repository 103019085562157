/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import type {
    ResidentialListingViewConfiguration,
    ListingCompany,
    GetResidentialListing,
} from '../../../../models/lexa';
import type { InfoProps } from '../type';
import getFragmentData from '../../../../util/getFragmentData';
import { AGENCY } from '../../../../models/lexa/listingCompanyType';
import AgencyInfo from './AgencyInfo.graphql';

type Props = {
    viewConfiguration: ResidentialListingViewConfiguration,
    listingCompany: ?ListingCompany,
};

const dataAdapter =
    (cidFrom: string) =>
    ({ viewConfiguration, listingCompany }: Props): ?InfoProps => {
        const cid = `agency-profile-page|${cidFrom}`;
        if (viewConfiguration.details.agencyInfo && listingCompany && listingCompany.__typename === AGENCY) {
            return {
                name: listingCompany.name,
                detailInfo: listingCompany.address.display.fullAddress,
                profileLink: listingCompany._links.canonical.href.replace('{cid}', cid),
            };
        }
        return null;
    };

export default (cidFrom: string): ((GetResidentialListing) => ?InfoProps) =>
    compose(dataAdapter(cidFrom), getFragmentData(AgencyInfo), (data: GetResidentialListing) => data.getListing());
