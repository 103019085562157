/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import type { ListingCompany, Lister, ResidentialDetailsGetters } from '../../../models/lexa';
import type { AgentInfoData } from './type';
import getFragmentData from '../../../util/getFragmentData';
import AgentInfo from './AgentInfo.graphql';

const AVATAR_PLACEHOLDER = 'https://assets.reastatic.net/realestate.com.au/profile-image-placeholder.svg';
const LISTER_PLACEHOLDER_ID = '1';
export type Props = {
    listers: ?Array<Lister>,
    listingCompany: ?ListingCompany,
};

export type DataType = ResidentialDetailsGetters;

const dataAdapter =
    (cid: string) =>
    ({ listers, listingCompany }: Props): AgentInfoData => {
        if (!listers || listers.length === 0) {
            const defaultLister = {
                id: LISTER_PLACEHOLDER_ID,
                templatedUrl: AVATAR_PLACEHOLDER,
                phoneNumber: listingCompany && listingCompany.businessPhone,
            };
            return {
                listers: [defaultLister],
                showDisclaimer: false,
            };
        }

        const mappedLister = listers.map((lister) => {
            const templatedUrl = lister.photo?.templatedUrl || AVATAR_PLACEHOLDER;
            const profileLink = lister._links.canonical && lister._links.canonical.href.replace('{cid}', cid);
            const phoneNumber = lister.phoneNumber ? lister.phoneNumber.display : null;
            return {
                id: lister.id,
                name: lister.name,
                templatedUrl,
                phoneNumber,
                profileLink,
            };
        });
        return {
            listers: mappedLister,
            showDisclaimer: listers.some(({ phoneNumber }) => phoneNumber && phoneNumber.showDisclaimer),
        };
    };

const getAdaptedListingData = (cid: string) =>
    compose(dataAdapter(cid), getFragmentData(AgentInfo), (data: DataType) => data.getListing());

const getEventTrackerData = (data: DataType) => ({
    revealNumberTracker: data.getEventTracker().agentMobileNumberReveal,
});

export default (cid: string) => (data: DataType) => ({
    ...getAdaptedListingData(cid)(data),
    ...getEventTrackerData(data),
});
