/* @flow */
import React from 'react';
import Address from './address';
import PropertyType from '../../property-type';
import Price from '../../price';
import { ResidentialPrimaryFeatures as PrimaryFeatures } from '../../primary-features';
import './styles.scss';
import type { ResidentialDetailsGetters } from '../../../models/lexa';
import type { Element } from 'react';
import { Inline, spacingHelper, typographyHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { FeatureToggle } from '../../toggles';

type Props = {
    data: ResidentialDetailsGetters,
    actionButtons: ?Element<*>,
    additionalContent: ?Element<*>,
    footerContent: ?Element<*>,
    propertySummaryBadge?: ?Element<*>,
};

const InlineStyled = styled(Inline)`
    margin-bottom: ${spacingHelper('extraSmall')};
    ${typographyHelper('body01')};
    flex-wrap: wrap;
`;

const AddressActionsWithBadge = styled.div`
    display: flex;
    flex-direction: column;
`;

const ActionsAndBadge = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-${spacingHelper('extraSmall')});

    ${({ theme }) => theme.breakpoints.large} {
        flex-direction: column-reverse;
        align-items: normal;
        transform: none;

        .property-info__actions {
            margin-bottom: ${spacingHelper('large')};
        }
    }
`;

const StyledPropertySummaryBadge = styled.div`
    ${({ theme }) => theme.breakpoints.large} {
        margin-bottom: ${spacingHelper('large')};
    }
`;

const View = ({ data, additionalContent, footerContent, actionButtons, propertySummaryBadge }: Props) => {
    const addressActions = (
        <div className="property-info__address-actions">
            <Address data={data} />
            <div className="property-info__actions">{actionButtons ? actionButtons : null}</div>
        </div>
    );

    const addressActionsWithBadge = (
        <AddressActionsWithBadge>
            <ActionsAndBadge>
                <StyledPropertySummaryBadge>
                    {propertySummaryBadge ? propertySummaryBadge : null}
                </StyledPropertySummaryBadge>
                <div className="property-info__actions">{actionButtons ? actionButtons : null}</div>
            </ActionsAndBadge>
            <Address data={data} />
        </AddressActionsWithBadge>
    );

    const renderHeaderWhenToggleIsOn = (data) => {
        if (data.__typename === 'BuyResidentialListing' && data.parent) {
            return addressActionsWithBadge;
        }
        return addressActions;
    };

    return (
        <div className="property-info">
            <div className="property-info__header">
                <FeatureToggle toggleName="projectLifecycle">
                    {(isToggledOn) => {
                        return isToggledOn ? renderHeaderWhenToggleIsOn(data.getListing()) : addressActions;
                    }}
                </FeatureToggle>
                <InlineStyled grow={false} gap="small" className="property-info__property-attributes">
                    <PrimaryFeatures data={data} className="property-info__primary-features" />
                    <PropertyType data={data} className="property-info__property-type" />
                </InlineStyled>
            </div>
            <div className="property-info__middle-content">
                <Price data={data} className="property-info__price" />
                {additionalContent}
            </div>
            {footerContent && <div className="property-info__footer-content">{footerContent}</div>}
        </div>
    );
};

export default View;
