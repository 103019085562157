/* @flow */
import React from 'react';

import type { GetResidentialListing } from '../../../../../models/lexa/index';
import adaptLexaData from './adaptLexaData';
import View from './View';

type Props = {
    data: GetResidentialListing,
    className: string,
};

const AgencyDescription = ({ data, className = '' }: Props) => {
    const maybeDescription = adaptLexaData(data);
    return maybeDescription ? <View description={maybeDescription} className={className} /> : null;
};

export default AgencyDescription;
