/* @flow */

import { getProductDepthConfiguration } from '../../../models/config/residential/ProductDepthConfiguration';
import type { GetResidentialListing, ResidentialListing, ListingCompany } from '../../../models/lexa';
import type { ProductDepth } from '../../../models/config/residential/DepthTypes';
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import ResidentialContactAgentBranding from './ResidentialContactAgentBranding.graphql';

type RequiredProps = {
    productDepth: ProductDepth,
    listingCompany: ?ListingCompany,
};

export type BrandingProps = {
    templatedUrl: string,
    brandingStyle: string,
    brandingLink: string,
    backgroundColor: string,
    altText: string,
};

const getBrandingInfo = (source, productDepth, brandingLink): ?BrandingProps => {
    if (!source.media.logo) return null;
    const backgroundColor = source.branding.primaryColour;
    const templatedUrl = source.media.logo.templatedUrl;
    const altText = source.name;
    const brandingStyle = getProductDepthConfiguration(productDepth).brandingStyle;
    return { brandingStyle, backgroundColor, templatedUrl, altText, brandingLink };
};

const dataAdapter =
    (listing: RequiredProps) =>
    (cid: string): ?BrandingProps => {
        const { listingCompany } = listing;
        if (listingCompany && listingCompany.media.logo) {
            const brandingLink = listingCompany._links.canonical.href.replace('{cid}', cid);
            return getBrandingInfo(listingCompany, listing.productDepth, brandingLink);
        }
    };

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, (string) => ?BrandingProps>(
    dataAdapter,
    getFragmentData(ResidentialContactAgentBranding),
    (data) => data.getListing()
);
