/* @flow */
import React, { type Element } from 'react';
import './styles.scss';
import type { GetResidentialListing } from '../../../../models/lexa/index';
import AgencyDescription from './agency-description';

type ViewProps = {
    data: GetResidentialListing,
    renderTrafficDriver?: () => Element<*>,
    singleColOnly?: boolean,
};

const View = ({ data, renderTrafficDriver, singleColOnly }: ViewProps) => {
    return (
        <div
            className={`project-listing-company-context ${
                singleColOnly ? 'project-listing-company-context--single-col-only' : ''
            }`}
        >
            {renderTrafficDriver && renderTrafficDriver()}
            <AgencyDescription className="project-listing-company-context__description" data={data} />
        </div>
    );
};

export default View;
