// @flow
import { canonical, description, openGraph, join, meta, schema, title } from '@rea-argonaut/actions';
import { type ResidentialListing } from '../../../models/lexa';
import { type Channel } from '../../../models/Channel';
import { type SchemaMarkup } from '../../../models/meta-data/types';
import { getHead as getCommonHead } from '../../../util/getHead';
import { getLexaHostClient } from '../../../config';
import getTitle from '../../../components/details/meta-data/residential/getTitle';
import getDescription from '../../../components/details/meta-data/residential/getDescription';
import getOpenGraphTitle from '../../../components/details/meta-data/residential/getOpenGraphTitle';
import getOpenGraphDescription from '../../../components/details/meta-data/residential/getOpenGraphDescription';
import {
    getOpenGraphImage,
    openGraphImageHeight,
    openGraphImageWidth,
} from '../../../components/details/meta-data/getOpenGraphImage';
import getOpenGraphUrl from '../../../components/details/meta-data/getOpenGraphUrl';
import { openGraphSiteName } from '../../../components/details/meta-data/openGraphSiteName';
import getAppSmartBanner from '../../../components/details/meta-data/getAppSmartBanner';
import { socialMediaData } from '../../../components/details/meta-data/socialMediaData';

export type Args<U> = {
    listing: U,
    channel: Channel,
    hostHeader: string,
    getSchemaMarkup: (listing: U, hostHeader: string) => Array<SchemaMarkup>,
};
const getHead = <U: ResidentialListing>(
    { listing, channel, hostHeader, getSchemaMarkup }: Args<U>,
    LEXA_HOST_CLIENT?: string = getLexaHostClient()
) => {
    const schemaMarkup = getSchemaMarkup(listing, hostHeader);

    return join(
        title(getTitle(channel)(listing)),
        getCommonHead(LEXA_HOST_CLIENT),
        canonical(listing._links.canonical.href),
        description(getDescription(channel)(listing).content),
        openGraph(getOpenGraphTitle(listing)),
        openGraph(getOpenGraphDescription(listing)),
        openGraph(getOpenGraphImage(listing)),
        openGraph(openGraphImageWidth),
        openGraph(openGraphImageHeight),
        openGraph(getOpenGraphUrl(listing)),
        openGraph(openGraphSiteName),
        meta(getAppSmartBanner(listing)),
        ...socialMediaData.map((item) => (item.property ? openGraph(item) : meta(item))),
        schemaMarkup.length ? schema(schemaMarkup) : ''
    );
};

export default getHead;
