/* @flow */

import React from 'react';
import View from '../View';
import adaptBuilderData from './adaptData';
import type { GetBuyResidentialListing } from '../../../../models/lexa';
import type { Cid } from '../type';

type Props = {
    data: GetBuyResidentialListing,
    className?: string,
    cidFrom: Cid,
};

const SidebarTrafficDriverForBuilderListing = ({ data, cidFrom, className = '' }: Props) => {
    const adaptedData = adaptBuilderData(cidFrom)(data);
    return adaptedData ? <View {...adaptedData} className={className} /> : null;
};

export default SidebarTrafficDriverForBuilderListing;
