/* @flow */
import React from 'react';
import View from '../../branding/View';
import adaptLexaData from './adaptLexaData';
import type { GetResidentialListing } from '../../../models/lexa';

type Props = {
    data: GetResidentialListing,
    className?: string,
    cid: string,
};

export const ContactAgentBranding = ({ data, className = '', cid }: Props) => {
    const adaptedData = adaptLexaData(data)(cid);
    if (!adaptedData) return null;
    const { brandingStyle, backgroundColor, templatedUrl, altText, brandingLink } = adaptedData;
    return (
        <View
            backgroundColor={backgroundColor}
            templatedUrl={templatedUrl}
            altText={altText}
            styleModifier={brandingStyle}
            brandingLink={brandingLink}
            className={`side-panel-branding ${className}`}
        />
    );
};
export { default as ContactAgentBrandingForChildListing } from './child-listing';
