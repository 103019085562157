/* @flow */

import React, { useState } from 'react';
import { P, Tooltip, spacingHelper, Inline } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import type { DisclaimerInteractionEvent } from '../../../client/tracking/event/types';

export type Props = {
    pageViews: ?{
        display: string,
        updatedTime: string,
        disclaimerInteractionEvent: (DisclaimerInteractionEvent) => void,
    },
};

const StyledInline = styled(Inline).attrs({ gap: '0' })`
    margin: ${spacingHelper('twoExtraSmall 0 medium medium')};

    ${({ theme }) => theme.breakpoints.medium} {
        margin: ${spacingHelper('twoExtraSmall 0 0 0')};
    }
`;

export default ({ pageViews }: Props) => {
    const [visited, setVisited] = useState(false);
    const pageViewsInfo = pageViews
        ? { content: `${pageViews.display} page views`, tooltip: `Last updated on ${pageViews.updatedTime}` }
        : { content: 'Page views unavailable', tooltip: 'Property page views can take up to 24 hours to appear.' };

    const onOpen = () => {
        if (!visited && pageViews) {
            pageViews.disclaimerInteractionEvent({
                user_action: 'hover',
                in_page_source: 'property views disclaimer',
            });
        }
        setVisited(true);
    };

    return (
        <StyledInline>
            <P variant="body02">{pageViewsInfo.content}</P>
            <Tooltip onOpen={onOpen}>
                {pageViewsInfo.tooltip}
                <br />
                <br />
                This number shows how many times property seekers have viewed this listing on realestate.com.au’s main
                website, mobile website and apps.
                <br />
                <br />
                Property owners should contact their agents for a comprehensive Campaign Activity Report which provides
                further information on interactions with this property.
            </Tooltip>
        </StyledInline>
    );
};
