import React from 'react';
import View from './View';
import type { ResidentialDetailsGetters } from '../../../../types';
import adaptLexaData from './adaptLexaData';

type Props = {
    data: ResidentialDetailsGetters;
    className?: string;
};

const Description = ({ data, className = '' }: Props) => {
    return <View {...adaptLexaData(data)} className={className} data={data} />;
};

export default Description;
