/* @flow */
import React from 'react';
import HeroThumbnail from '../hero-thumbnail';
import type { Thumbnail } from '../types';
import './styles.scss';

type Props = {
    thumbnails: Array<Thumbnail>,
    className: string,
};

export default ({ thumbnails, className }: Props) => {
    return (
        <div className={`hero-thumbnails ${className}`} data-testid="hero-thumbnails">
            {thumbnails.map((thumbnail, index) => (
                <HeroThumbnail key={index} className={'hero-thumbnails__hero-thumbnail'} {...thumbnail} />
            ))}
        </div>
    );
};
