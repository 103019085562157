/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import type { GetBuyResidentialListing, ProjectProfile, BuyResidentialListing } from '../../../../models/lexa/index';
import createPicture from '../../../../models/Picture';
import type { Channel } from '../../../../models/Channel';
import type { ImageProps } from '../type';
import EnquiryProjectMainImage from './EnquiryProjectMainImage.graphql';

type RequiredProps = {
    parent: ?ProjectProfile,
};

const dataAdapter =
    (channel: Channel) =>
    ({ parent }: RequiredProps): ?ImageProps => {
        if (parent && parent.media.mainImage) {
            const cid = `pppdp|${channel}:pdp:contact-form`;
            return {
                mainImage: createPicture(parent.media.mainImage.templatedUrl).ofSize('300x170'),
                profileLink: `${parent._links.canonical.path}?cid=${cid}`,
            };
        }
        return null;
    };

export default (channel: Channel) =>
    compose<GetBuyResidentialListing, BuyResidentialListing, RequiredProps, ?ImageProps>(
        dataAdapter(channel),
        getFragmentData(EnquiryProjectMainImage),
        (data) => data.getListing()
    );
