/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import type { Builder, ListingCompany } from '../../../../models/lexa/index';
import type { InfoProps } from '../type';
import { BUILDER } from '../../../../models/lexa/listingCompanyType';
import type { GetResidentialListing } from '../../../../models/lexa';
import BuilderInfo from './BuilderInfo.graphql';

type Props = {
    listingCompany: ?ListingCompany,
};

const dataAdapter =
    (cidFrom: string) =>
    ({ listingCompany }: Props): ?InfoProps => {
        const cid = `nhbpp|${cidFrom}`;
        const builder = ((listingCompany: any): Builder);
        if (builder && builder.__typename === BUILDER && builder.homeDesigns) {
            const totalCount = builder.homeDesigns.totalCount;
            const numberOfHomeDesigns = totalCount > 1 ? `${totalCount} home designs` : 'home designs';
            return {
                name: builder.name,
                detailInfo: `Explore ${numberOfHomeDesigns} from ${builder.name}`,
                profileLink: builder._links.canonical.href.replace('{cid}', cid),
            };
        }
        return null;
    };

export default (cidFrom: string): ((GetResidentialListing) => ?InfoProps) =>
    compose(dataAdapter(cidFrom), getFragmentData(BuilderInfo), (data) => data.getListing());
