/* @flow */
import React from 'react';
import View from './View';
import adaptListingId from './adaptListingId';
import adaptListingMetrics from './adaptListingMetrics';
import adaptViewConfiguration from './adaptViewConfiguration';
import type { ResidentialDetailsGetters } from '../../../models/lexa';

type DataProps = {
    data: ResidentialDetailsGetters,
    showPageViews: boolean,
    className?: string,
};

const ListingMetrics = ({ data, showPageViews, className = '' }: DataProps) => {
    return (
        <View
            className={className}
            propertyId={adaptListingId(data)}
            pageViews={adaptListingMetrics(data)}
            showPageViews={showPageViews}
            upgradeOfferUrl={adaptViewConfiguration(data)}
        />
    );
};

export default ListingMetrics;
