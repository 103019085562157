/* @flow */
import React from 'react';
import { View as Branding } from '../../branding';
import styled from 'styled-components';

type Props = {
    backgroundColor: string,
    link: string,
    templatedUrl: ?string,
    altText: string,
};

const HeaderBranding = styled.div`
    ${({ theme }) => theme.breakpoints.large} {
        padding: 10px 0;
    }
    img {
        height: 32px;
    }
    background-color: ${(props) => props.$backgroundColor};
`;

const View = ({ backgroundColor, link, templatedUrl, altText }: Props) => {
    return (
        <HeaderBranding $backgroundColor={backgroundColor}>
            <Branding
                styleModifier="centre"
                brandingLink={link}
                templatedUrl={templatedUrl}
                altText={altText}
                backgroundColor={backgroundColor}
            />
        </HeaderBranding>
    );
};

export default View;
