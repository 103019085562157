/* @flow */

import React from 'react';
import PageViewsContent from './PageView';
import { Link, P, spacingHelper, typographyHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import type { Props as PageViewsProps } from './PageView';

type Props = {
    propertyId: string,
    showPageViews: boolean,
    upgradeOfferUrl: ?string,
} & PageViewsProps;

const StyledText = styled(P)`
    margin: ${spacingHelper('extraSmall 0 extraSmall medium')};

    ${({ theme }) => theme.breakpoints.medium} {
        margin: ${spacingHelper('medium 0 0 0')};
        flex: unset;
    }

    ${({ theme }) => theme.breakpoints.large} {
        flex: 0.4;
    }
`;

const StyledLink = styled(Link)`
    ${typographyHelper('body02')};
    margin: ${spacingHelper('0 medium extraSmall medium')};

    ${({ theme }) => theme.breakpoints.medium} {
        margin: 0 0 0 0;
        flex: unset;
    }

    ${({ theme }) => theme.breakpoints.large} {
        flex: 0.6;
    }
`;

const PageViewsWrapper = styled.div`
    display: flex;
`;

const ListingMetricsWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: ${spacingHelper('extraSmall')};
    box-sizing: border-box;
    justify-content: space-between;

    ${({ theme }) => theme.breakpoints.large} {
        justify-content: unset;
    }
`;

const ListingMetrics = ({ propertyId, pageViews, showPageViews, upgradeOfferUrl }: Props) => {
    return (
        <ListingMetricsWrapper>
            <StyledText variant="body02">Property ID: {propertyId}</StyledText>
            {upgradeOfferUrl && (
                <StyledLink href={upgradeOfferUrl} variant="secondary">
                    Promote this property
                </StyledLink>
            )}
            {showPageViews && (
                <PageViewsWrapper>
                    <PageViewsContent pageViews={pageViews} />
                </PageViewsWrapper>
            )}
        </ListingMetricsWrapper>
    );
};

export default ListingMetrics;
