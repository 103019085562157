/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import type {
    GetResidentialListing,
    ListingCompany,
    ResidentialListingViewConfiguration,
    ResidentialListing,
} from '../../../../models/lexa';
import createPicture from '../../../../models/Picture';
import type { Channel } from '../../../../models/Channel';
import type { ImageProps } from '../type';
import { AGENCY } from '../../../../models/lexa/listingCompanyType';
import EnquiryAgencyMainImage from './EnquiryAgencyMainImage.graphql';

type RequiredProps = {
    listingCompany: ?ListingCompany,
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter =
    (channel: Channel) =>
    ({ listingCompany, viewConfiguration }: RequiredProps): ?ImageProps => {
        if (
            viewConfiguration.details.agencyInfo &&
            listingCompany &&
            listingCompany.media.mainImage &&
            listingCompany.__typename === AGENCY
        ) {
            const cid = `agency-profile-page|${channel}:pdp:contact-form&sourcePage=${channel}:property-details&sourceElement=agency-branding`;
            return {
                mainImage: createPicture(listingCompany.media.mainImage.templatedUrl).ofSize('300x170'),
                profileLink: listingCompany._links.canonical.href.replace('{cid}', cid),
            };
        }
        return null;
    };

export default (channel: Channel) =>
    compose<GetResidentialListing, ResidentialListing, RequiredProps, ?ImageProps>(
        dataAdapter(channel),
        getFragmentData(EnquiryAgencyMainImage),
        (data) => data.getListing()
    );
