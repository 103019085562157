import React, { useRef } from 'react';
import './styles.scss';
import { TellMeMore } from '../../tell-me-more';
import type { ResidentialDetailsGetters } from '../../../../types';
import { ResidentialListingAddress } from '../../property-card/address';
import SectionHeading from '../section-heading';

type ViewProps = {
    eventTracker: () => void;
    title: string;
    description: string;
    className?: string;
    data: ResidentialDetailsGetters;
};

const View = ({ title, description, eventTracker, data, className = '' }: ViewProps) => {
    const el = useRef(null);
    return (
        <article className={`property-description ${className}`}>
            {title && <SectionHeading ref={el}>{title}</SectionHeading>}
            <ResidentialListingAddress className="property-description__address" data={data} />
            <TellMeMore trackShowMoreClick={eventTracker} scrollToElement={el.current}>
                <span className="property-description__content" dangerouslySetInnerHTML={{ __html: description }} />
            </TellMeMore>
        </article>
    );
};

export default View;
