/* @flow */

import type { Element as ReactElement } from 'react';
import type { LexaImage } from '../../../models/lexa';
import type { Thumbnail } from '../types';

const createThumbnail = ({
    image,
    overlay,
    action,
    externalAction,
    label,
    altText,
}: {
    image: LexaImage,
    overlay?: ReactElement<*>,
    action: () => void,
    externalAction?: string,
    label: string,
    altText: string,
}): Thumbnail => {
    return {
        templatedUrl: image.templatedUrl,
        overlay,
        action,
        externalAction,
        label,
        altText,
    };
};

export default createThumbnail;
