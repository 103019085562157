/* @flow */

import React from 'react';
import { type SlideName, SlideNames } from '../../media-viewer';
import type { MediaIconClickType } from '../../../client/tracking/event/types';
import type { LexaImage, Video, ThreeDimensionalTour } from '../../../models/lexa';
import { option, none, some } from '../../../util/functional/Option';
import type { Thumbnail } from '../types';
import createThumbnail from './createThumbnail';
import { PlayVideoOverlay } from '../overlay';

const createMediaThumbnail = (
    openMediaViewer: (name: SlideName) => void,
    mediaViewerIconClick: (type: MediaIconClickType) => void,
    mainImage: LexaImage,
    videos: Array<Video>,
    threeDimensionalTours: Array<ThreeDimensionalTour>
): Array<Thumbnail> => {
    const maybeFirstVideo = option(videos[0]);
    const youtubeVideos = maybeFirstVideo.flatMap((video) => {
        return video.__typename === 'YouTubeVideo'
            ? some(
                  createThumbnail({
                      image: mainImage,
                      overlay: <PlayVideoOverlay />,
                      action: () => {
                          openMediaViewer(SlideNames.VIDEO);
                          mediaViewerIconClick('video');
                      },
                      label: 'videos available',
                      altText: 'video',
                  })
              )
            : none;
    });
    const externalVideos = maybeFirstVideo.flatMap((video) => {
        return video.__typename === 'ExternalVideo'
            ? some(
                  createThumbnail({
                      image: mainImage,
                      overlay: <PlayVideoOverlay />,
                      action: () => mediaViewerIconClick('video'),
                      externalAction: video.href,
                      label: 'videos available',
                      altText: 'video',
                  })
              )
            : none;
    });
    const maybeThreeDimensionalTourVideo = option(threeDimensionalTours[0]);
    const threeDimensionalTourThumbnail = maybeThreeDimensionalTourVideo.flatMap(() => {
        return some(
            createThumbnail({
                image: mainImage,
                overlay: <PlayVideoOverlay />,
                action: () => {
                    openMediaViewer(SlideNames.VIRTUAL_TOUR);
                    mediaViewerIconClick('3DTour');
                },
                label: 'virtualtours available',
                altText: 'virtual tours',
            })
        );
    });

    return [
        youtubeVideos.getOrElse(null),
        externalVideos.getOrElse(null),
        threeDimensionalTourThumbnail.getOrElse(null),
    ]
        .filter(Boolean)
        .slice(0, 1);
};

export default createMediaThumbnail;
