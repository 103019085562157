/* @flow */

import React from 'react';
import type { Element as ReactElement } from 'react';

const PlayVideoOverlay = (): ReactElement<*> => (
    <div className="hero-thumbnail__overlay">
        <span className="hero-thumbnail__play-video-icon" />
    </div>
);

const ImageCounterOverlay = ({ imagesCounter }: { imagesCounter: number }): ReactElement<*> => (
    <div className="hero-thumbnail__overlay">
        <span className="hero-thumbnail__image-counter">+{imagesCounter}</span>
    </div>
);

export { PlayVideoOverlay, ImageCounterOverlay };
