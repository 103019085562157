import React from 'react';
import { AdaptedProps as Props } from './types';
import {
    PersonalisedPropertyFeaturesContainer,
    TopPropertyFeatureItem,
    TopPropertyFeaturesItemsContainer,
} from './styles';
import PropertyFeaturesIcon from './PropertyFeaturesIcon';
import ExpandoCollapso from '../../components/expando-collapso';
import { H2, P } from '@rea-group/construct-kit-core';

const View = ({ topPropertyFeatures }: Props) => {
    const allPropertyFeatures = (): React.ReactNode => {
        return <div>All Property Features Placeholder</div>;
    };
    return (
        <PersonalisedPropertyFeaturesContainer>
            <H2 variant="title02">{topPropertyFeatures.title}</H2>
            <TopPropertyFeaturesItemsContainer>
                {topPropertyFeatures.propertyFeatureItems.map((item) => (
                    <TopPropertyFeatureItem key={item.propertyFeatureId}>
                        <PropertyFeaturesIcon id={item.propertyFeatureId} />
                        <P>{item.displayLabel}</P>
                    </TopPropertyFeatureItem>
                ))}
            </TopPropertyFeaturesItemsContainer>
            {topPropertyFeatures.showMore && (
                <ExpandoCollapso
                    buttonText={(isOpen) => (isOpen ? 'Show less' : (topPropertyFeatures.showMore?.title ?? ''))}
                >
                    {allPropertyFeatures()}
                </ExpandoCollapso>
            )}
        </PersonalisedPropertyFeaturesContainer>
    );
};

export default View;
