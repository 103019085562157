/* @flow */

import { format, parseISO } from 'date-fns';
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { GetListingMetrics, ListingMetrics, ResidentialDetailsGetters } from '../../../models/lexa';
import PageViews from './PageViews.graphql';

type AdaptedProps = {
    display: string,
    updatedTime: string,
};

const dataAdapter = (listingMetrics: ?ListingMetrics): ?AdaptedProps => {
    if (listingMetrics && listingMetrics.pageViews) {
        const pageViewsDisplayValue = listingMetrics.pageViews.display;
        const lastUpdatedTime = format(parseISO(listingMetrics.pageViews.lastUpdated.value), 'dd MMM yyyy');

        return {
            display: pageViewsDisplayValue,
            updatedTime: lastUpdatedTime,
        };
    }

    return null;
};

const listingMetricsAdapter = compose<GetListingMetrics, ?ListingMetrics, ?ListingMetrics, ?AdaptedProps>(
    dataAdapter,
    getFragmentData(PageViews),
    (data) => data.getListingMetrics()
);

const adaptLexaData = (data: ResidentialDetailsGetters) => {
    const lexaProps = listingMetricsAdapter(data);
    const disclaimerInteractionEvent = data.getEventTracker().disclaimerInteractionEvent;
    return lexaProps
        ? {
              ...lexaProps,
              disclaimerInteractionEvent,
          }
        : null;
};

export default adaptLexaData;
