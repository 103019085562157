/* @flow */
import type { ResidentialListing } from '../../../../models/lexa';
import type { Address } from '../../../../models/lexa/addressType';
import { DEFAULT_DISPLAY_ADDRESS } from '../../../../models/Address';

const handleAddress = (address: Address) => {
    const fullAddress = address.display.fullAddress;
    const { suburb } = address;
    return fullAddress ? fullAddress : `${DEFAULT_DISPLAY_ADDRESS}, ${suburb}`;
};

export default ({ address }: ResidentialListing) => ({
    property: 'og:title',
    content: handleAddress(address),
});
