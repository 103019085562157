/* @flow */

import { compose3 as compose } from '../../../../../util/functional/compose';
import getFragmentData from '../../../../../util/getFragmentData';
import type {
    GetResidentialListing,
    ListingCompany,
    ResidentialListingViewConfiguration,
    ResidentialListing,
} from '../../../../../models/lexa/index';
import { AGENCY } from '../../../../../models/lexa/listingCompanyType';
import AgencyDescription from './AgencyDescription.graphql';

type RequiredProps = {
    listingCompany: ?ListingCompany,
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = ({ listingCompany, viewConfiguration }: RequiredProps): ?string =>
    viewConfiguration.details.agencyInfo && listingCompany && listingCompany.__typename === AGENCY
        ? listingCompany.description
        : null;

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, ?string>(
    dataAdapter,
    getFragmentData(AgencyDescription),
    (data: GetResidentialListing) => data.getListing()
);
