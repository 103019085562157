/* @flow */
import React, { Fragment } from 'react';
import type { Element as ReactElement } from 'react';
import placeholderSrc from './placeholder.svg';
import './styles.scss';
import { TemplatedLazyImage } from '../../Image';

type Props = {
    altText: string,
    templatedUrl: string,
    className: string,
    action: () => void,
    externalAction?: string,
    overlay?: ReactElement<*>,
    label: string,
};

export default ({ altText, templatedUrl, overlay, action, externalAction, className, label }: Props) => {
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            action();
        }
    };

    const renderThumbnailContent = () => (
        <Fragment>
            <TemplatedLazyImage
                templatedUrl={templatedUrl}
                size="360x270"
                lazy={true}
                avif={true}
                alt={altText}
                placeholder={placeholderSrc}
            />
            {overlay}
        </Fragment>
    );

    if (externalAction) {
        return (
            <a
                className={`hero-thumbnail ${className}`}
                href={externalAction}
                onClick={action}
                target="_blank"
                rel="noopener noreferrer nofollow"
                aria-label={label}
            >
                {renderThumbnailContent()}
            </a>
        );
    }

    return (
        <div
            role="button"
            className={`hero-thumbnail ${className}`}
            onClick={action}
            aria-label={label}
            tabIndex="0"
            onKeyPress={handleKeyPress}
        >
            {renderThumbnailContent()}
        </div>
    );
};
