// @flow
import { setTransactionMetadata } from '@rea-argonaut/observability';
import adaptLexaData from './adaptLexaData';
import type { GetResidentialListing } from '../../../models/lexa';

type Props = {
    data: GetResidentialListing,
};

const CustomReporting = ({ data }: Props) => {
    const { customReportingMetadata } = adaptLexaData(data);

    customReportingMetadata.map((metadata) => {
        setTransactionMetadata(metadata);
    });

    return null;
};

export default CustomReporting;
