/* @flow */
import React from 'react';
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import { DEFAULT_DISPLAY_ADDRESS } from '../../models/Address';
import type { ResidentialDetailsGetters } from '../../models/lexa';
import type {
    GetResidentialListing,
    ResidentialListingMedia,
    Address,
    ResidentialListingViewConfiguration,
} from '../../models/lexa';
import type { MediaIconClickType } from '../../client/tracking/event/types';
import { type SlideName } from '../media-viewer';
import type { Thumbnail } from './types';
import { ImageCounterOverlay } from './overlay';
import { createFloorplanThumbnail, createMediaThumbnail, createImageThumbnails } from './factory';
import HeroImage from './HeroImage.graphql';

type RequiredData = {
    address: Address,
    media: ResidentialListingMedia,
    viewConfiguration: ResidentialListingViewConfiguration,
};

type AdaptedProps = {
    mainImageTemplatedUrl: string,
    altText: string,
    thumbnails: Array<Thumbnail>,
    posterBoard: boolean,
};

const hasFloorplan = (floorplans) => floorplans && floorplans.length >= 1;

const getAddress = (address: Address) => address.display.fullAddress || DEFAULT_DISPLAY_ADDRESS;

const getImagesCounter = (media: ResidentialListingMedia): number => {
    const { images, floorplans, videos, threeDimensionalTours, mainImage } = media;
    const imagesWithoutMainImage = images.slice(1);
    const hasOtherImages = imagesWithoutMainImage.length > 0;
    const hasVideo = videos.length > 0 || threeDimensionalTours.length > 0;
    const imagesCounter =
        imagesWithoutMainImage.length +
        (hasFloorplan(floorplans) ? 1 : 0) +
        (hasVideo ? 1 : 0) -
        (mainImage ? 1 : 0) -
        (hasOtherImages ? 1 : 0);
    return imagesCounter;
};

const heroImageAdapter =
    (openMediaViewer: (name: SlideName) => void) =>
    ({ address, media, viewConfiguration }: RequiredData) =>
    (mediaViewerIconClick: (type: MediaIconClickType) => void): AdaptedProps => {
        const { mainImage, images, floorplans, videos, threeDimensionalTours } = media;
        const posterBoard = viewConfiguration.details.posterBoard;

        let thumbnails = [];
        const imagesWithoutMainImage = images.slice(1);
        const floorplanThumbnail = createFloorplanThumbnail(openMediaViewer, mediaViewerIconClick, floorplans);
        const videoThumbnail = createMediaThumbnail(
            openMediaViewer,
            mediaViewerIconClick,
            mainImage,
            videos,
            threeDimensionalTours
        );
        const imageThumbnails = createImageThumbnails(openMediaViewer, imagesWithoutMainImage, address);
        thumbnails = [...floorplanThumbnail, ...videoThumbnail, ...imageThumbnails].slice(0, 3);

        if (thumbnails.length >= 3) {
            const imagesCounter = getImagesCounter(media);
            if (imagesCounter > 1) {
                thumbnails[2].overlay = <ImageCounterOverlay imagesCounter={imagesCounter} />;
            }
        }
        return {
            mainImageTemplatedUrl: mainImage.templatedUrl,
            altText: getAddress(address),
            thumbnails,
            posterBoard,
        };
    };

const getListingData = (openMediaViewer: (name: SlideName) => void) =>
    compose(heroImageAdapter(openMediaViewer), getFragmentData(HeroImage), (data: GetResidentialListing) =>
        data.getListing()
    );

export default (openMediaViewer: (name: SlideName) => void) => (data: ResidentialDetailsGetters) => {
    const listingData = getListingData(openMediaViewer)(data);
    const mediaViewerIconClick = data.getEventTracker().mediaViewerIconClick;
    return listingData(mediaViewerIconClick);
};
