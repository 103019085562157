/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type {
    GetResidentialListing,
    ResidentialListingViewConfiguration,
    ResidentialListing,
} from '../../../models/lexa';
import PromotePropertyViewConfiguration from './PromotePropertyViewConfiguration.graphql';

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
    +__typename: string,
};

const dataAdapter = ({ viewConfiguration }: RequiredProps): ?string => {
    if (viewConfiguration.details.promoteProperty === true) {
        return 'https://customer.realestate.com.au/residential/property-advertising/';
    }
    return null;
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, ?string>(
    dataAdapter,
    getFragmentData(PromotePropertyViewConfiguration),
    (data) => data.getListing()
);
