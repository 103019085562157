/* @flow */

import React, { useContext, type Node } from 'react';
import View from './View';
import ChannelContext from '../../../util/ChannelContext';
import type { GetResidentialListing } from '../../../models/lexa';
import type { Channel } from '../../../models/Channel';
import type { ImageProps } from './type';

type Adapter<GetListing> = (Channel) => (GetListing) => ?ImageProps;
type Props<GetListing> = {
    data: GetListing,
};

const createView =
    <GetListing: GetResidentialListing>(adaptData: Adapter<GetListing>): ((Props<GetListing>) => Node) =>
    ({ data }: Props<GetListing>) => {
        const channel = useContext(ChannelContext);
        const adaptedData = adaptData(channel)(data);
        return adaptedData ? <View {...adaptedData} /> : null;
    };

export default createView;
