/* @flow */
const pluralize = (word: string, count: number): string => {
    if (count <= 1) {
        return word;
    } else {
        return word + 's';
    }
};

export default pluralize;
