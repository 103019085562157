// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { type DetailsData } from '../detailsData';
import { DetailsHeaderLeaderboard } from '../../../components/advert';
import { type ResidentialDetailsGetters } from '../../../models/lexa';
import { type GetPropertyDetailsGetters } from '../../PropertyDetailsGetters';

type Props = {
    initialData: DetailsData,
    getGetters: (args: GetPropertyDetailsGetters) => ResidentialDetailsGetters,
};

const ResidentialDetailsPreHeader = ({ initialData, getGetters }: Props) => {
    const fullDetailsData = useSelector((state) => state.headerLeaderboard.fullDetailsData);
    let data = initialData;

    if (fullDetailsData?.value.details.listing.id === initialData.value.details.listing.id) {
        data = fullDetailsData;
    }
    return <DetailsHeaderLeaderboard data={getGetters({ data })} />;
};

export default ResidentialDetailsPreHeader;
