/* @flow */
import React, { Fragment, Component } from 'react';
import './styles.scss';
import PhoneNumber from '../phone-number/View.js';
import CallLeadAttributionDisclaimer from '../call-lead-attribution-disclaimer';
import { Link } from '@rea-group/construct-kit-core';
import type { ViewProps } from './type';
import styled from 'styled-components';
import { TemplatedLazyImage } from '../../Image';
import AgentRatings from '../agent-ratings';

const StyledCallLeadAttributionComponent = styled(CallLeadAttributionDisclaimer)`
    margin-top: ${({ theme, $isHorizontal }) => ($isHorizontal ? theme.spacing.extraSmall : 0)};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
    margin-left: ${({ theme, $isHorizontal }) => ($isHorizontal ? 0 : theme.spacing.medium)};
`;

class View extends Component<ViewProps> {
    createNumberRevealTracker = () => {
        this.props.revealNumberTracker(this.props.revealPageSource);
    };

    render() {
        const { data, revealPageSource, listers, showDisclaimer, isHorizontal = false } = this.props;

        return (
            <Fragment>
                <ul className={`agent-info ${isHorizontal ? 'agent-info--horizontal' : ''}`}>
                    {listers.map((lister, index) => (
                        <AgentDetail
                            {...lister}
                            data={data}
                            key={index}
                            revealNumberTracker={this.createNumberRevealTracker}
                        />
                    ))}
                </ul>
                {showDisclaimer && (
                    <StyledCallLeadAttributionComponent
                        data={data}
                        $isHorizontal={isHorizontal}
                        pageSource={revealPageSource}
                    />
                )}
            </Fragment>
        );
    }
}

const AgentDetail = ({ id, data, profileLink, templatedUrl, name, phoneNumber, revealNumberTracker }) => (
    <li className="agent-info__agent">
        <div className="agent-info__photo">
            <a href={profileLink}>
                <TemplatedLazyImage
                    className="agent-info__photo-image"
                    templatedUrl={templatedUrl}
                    size="150x200"
                    defaultJpeg={true}
                    avif={true}
                    alt={name || 'agent photo'}
                />
            </a>
        </div>
        <div className="agent-info__contact-info">
            <div>
                {renderName(profileLink, name)}
                <AgentRatings listerId={id} data={data} />
            </div>
            {phoneNumber && <PhoneNumber number={phoneNumber} revealNumberTracker={revealNumberTracker} />}
        </div>
    </li>
);

const renderName = (profileLink, name) =>
    profileLink ? (
        <Link className="agent-info__name" href={profileLink}>
            {name}
        </Link>
    ) : (
        <div className="agent-info__name">{name}</div>
    );

export default View;
