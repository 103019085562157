/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import type { GetResidentialListing, ResidentialListing, Builder, ListingCompany } from '../../../../models/lexa';
import { BUILDER } from '../../../../models/lexa/listingCompanyType';
import createPicture from '../../../../models/Picture';
import type { Channel } from '../../../../models/Channel';
import type { ImageProps } from '../type';
import EnquiryBuilderMainImage from './EnquiryBuilderMainImage.graphql';

type Props = {
    listingCompany: ?ListingCompany,
};

const dataAdapter =
    (channel: Channel) =>
    ({ listingCompany }: Props): ?ImageProps => {
        const builder = ((listingCompany: any): Builder);
        if (builder && builder.__typename === BUILDER && builder.homeDesigns && builder.media.mainImage) {
            const cid = `nhbpp|${channel}:pdp:contact-form`;
            return {
                mainImage: createPicture(builder.media.mainImage.templatedUrl).ofSize('300x170'),
                profileLink: builder._links.canonical.href.replace('{cid}', cid),
            };
        }
        return null;
    };

export default (channel: Channel) =>
    compose<GetResidentialListing, ResidentialListing, Props, ?ImageProps>(
        dataAdapter(channel),
        getFragmentData(EnquiryBuilderMainImage),
        (data) => data.getListing()
    );
