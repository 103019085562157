/* @flow */
import React from 'react';
import View from '../View';
import adaptParentProjectData from '../adaptParentProjectData';
import type { GetBuyResidentialListing } from '../../../../models/lexa';

type Props = {
    data: GetBuyResidentialListing,
};

const HeaderBrandingForChildListing = ({ data }: Props) => {
    const adaptedData = adaptParentProjectData(data);
    return adaptedData ? <View {...adaptedData} /> : null;
};

export default HeaderBrandingForChildListing;
