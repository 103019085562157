/* @flow */
import React, { useContext } from 'react';
import adaptLexaData from './adaptLexaData';
import View from './View';
import type { ResidentialDetailsGetters } from '../../models/lexa';
import type { Element as ReactElement } from 'react';
import MediaViewerContext from '../media-viewer/provider/MediaViewerContext';

type Props = {
    data: ResidentialDetailsGetters,
    renderMediaOverview: (onClose: () => void) => ReactElement<*>,
    pip: ReactElement<*>,
    badgeContainer?: ReactElement<*>,
    isMobileScreen?: () => boolean,
};

export const InteractiveHero = ({ data, pip, badgeContainer, renderMediaOverview }: Props) => {
    const { openMediaViewer } = useContext(MediaViewerContext);
    return (
        <View
            {...adaptLexaData(openMediaViewer)(data)}
            pip={pip}
            badgeContainer={badgeContainer}
            openMediaViewer={openMediaViewer}
            data={data}
            renderMediaOverview={renderMediaOverview}
        />
    );
};
