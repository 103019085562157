/* @flow */
import React from 'react';
import './styles.scss';

type ViewProps = {
    description: string,
    className: string,
};

const View = ({ description, className }: ViewProps) => (
    <div className={className}>
        <div className="agency-description">{description}</div>
    </div>
);

export default View;
