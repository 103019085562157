/* @flow */
import React from 'react';
import './styles.scss';
import { Link } from '@rea-group/construct-kit-core';
import type { InfoProps } from './type';

const View = ({ name, detailInfo, className = '', profileLink }: InfoProps & { className?: string }) => {
    return (
        <div className={`sidebar-traffic-driver ${className}`}>
            <Link href={profileLink} className="sidebar-traffic-driver__name">
                {name}
            </Link>
            <div className="sidebar-traffic-driver__detail-info">{detailInfo}</div>
        </div>
    );
};

export default View;
