// @flow
import camelCase from 'lodash/camelCase';
import type { GetResidentialListing, ResidentialListing } from '../../../models/lexa';
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';

import CustomReporting from './CustomReporting.graphql';

type TransactionMetadata = {
    splunkKey?: string,
    newRelicKey: string,
    value: string | number | boolean,
};

type RequiredProps = ResidentialListing;

type AdaptedProps = {
    customReportingMetadata: TransactionMetadata[],
};

const isDetailsPosterBoard = 'is_details_poster_board';
const isDetailsChildListing = 'is_details_child_listing';

const dataAdapter = (listing: RequiredProps): AdaptedProps => {
    if (listing.__typename === 'BuyResidentialListing')
        return {
            customReportingMetadata: [
                {
                    splunkKey: isDetailsPosterBoard,
                    newRelicKey: camelCase(isDetailsPosterBoard),
                    value: listing.viewConfiguration.details.posterBoard,
                },
                {
                    splunkKey: isDetailsChildListing,
                    newRelicKey: camelCase(isDetailsChildListing),
                    value: !!listing['parent'],
                },
            ],
        };

    return {
        customReportingMetadata: [
            {
                splunkKey: isDetailsPosterBoard,
                newRelicKey: camelCase(isDetailsPosterBoard),
                value: listing.viewConfiguration.details.posterBoard,
            },
        ],
    };
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedProps>(
    dataAdapter,
    getFragmentData(CustomReporting),
    (data) => data.getListing()
);
