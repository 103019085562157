/* @flow */
import type { LexaImage } from './lexaImageType';
import type { HomeDesign } from './homeDesignType';

export type ListingCompanyMediaType = {
    logo: ?LexaImage,
    mainImage: ?LexaImage,
};

export type ListingCompanyBrandingType = {
    primaryColour: string,
    textColour: string,
};

export type ListingCompanyLinksType = {
    canonical: {
        templated: boolean,
        href: string,
    },
};

export type ListingCompanyAddressType = {
    display: {
        fullAddress: string,
    },
};

export type ListingCompanyCommonFields = {
    id: string,
    name: string,
    businessPhone: string,
    media: ListingCompanyMediaType,
    branding: ListingCompanyBrandingType,
    _links: ListingCompanyLinksType,
    description: ?string,
    address: ListingCompanyAddressType,
    ratingsReviews: ?RatingsReviews,
};

export type HomeDesigns = {
    totalCount: number,
    designs: Array<HomeDesign>,
};

export type RatingsReviews = {
    totalReviews: number,
    avgRating: ?number,
};

export type Builder = ListingCompanyCommonFields & {
    __typename: 'Builder',
    homeDesigns: ?HomeDesigns,
};

export type Agency = ListingCompanyCommonFields & { __typename: 'Agency' };

export type Developer = ListingCompanyCommonFields & { __typename: 'Developer' };

export type ListingCompany = Builder | Agency | Developer;

export const BUILDER = 'Builder';
export const AGENCY = 'Agency';
