/* @flow */
import { type Element } from 'react';
import { shouldRenderBelowTheFold } from './details';
import type { DetailsData } from './details';
import type {
    SoldResidentialListingDetails,
    BuyResidentialListingDetails,
    RentResidentialListingDetails,
    SoldResidentialListingDetailsGetters,
    BuyResidentialListingDetailsGetters,
    RentResidentialListingDetailsGetters,
    TrackingData,
} from '../models/lexa';
import { IS_CLIENT } from '../config/common';
import type { Channel as ChannelType } from '../models/Channel';
import { createTrackerFromListing } from '../client/tracking/event';
import {
    createPageTrackerFromResidentialListing as createPageTrackerFromListing,
    mergeResidentialListingDetailsTrackingDataWithPage,
} from '../client/tracking/page';
import { buyDetailsExperiments, rentDetailsExperiments, soldDetailsExperiments } from '../experiments/experiments';

export type GetPropertyDetailsGetters = {
    data: DetailsData,
    isClient?: boolean,
    createTrackingData?: (channel: ChannelType, trackingData: string) => TrackingData,
};
type Props<Getters> = GetPropertyDetailsGetters & {
    children: (Getters) => Element<*>,
};

export const getSoldPropertyDetailsGetters = ({
    data,
    createTrackingData = mergeResidentialListingDetailsTrackingDataWithPage(),
    isClient = IS_CLIENT,
}: GetPropertyDetailsGetters) => {
    if (data.value.details.__typename === 'SoldResidentialListingDetails') {
        const details: SoldResidentialListingDetails = data.value.details;
        const hasFullDetails = shouldRenderBelowTheFold(data);
        const eventTracker = createTrackerFromListing(details.listing);
        const trackingData = isClient && hasFullDetails ? createTrackingData('sold', details.trackingData) : null;
        const pageTracker = hasFullDetails
            ? createPageTrackerFromListing('sold', details.trackingData)
            : { track: () => false };
        const soldDetailsGetters: SoldResidentialListingDetailsGetters = {
            getProperty: () => details.property,
            getListing: () => details.listing,
            getMarketInsights: () => details.marketInsights,
            getRecentSales: () => details.recentSales,
            getListingMetrics: () => details.listingMetrics,
            getEventTracker: () => eventTracker,
            getTrackingData: () => trackingData,
            getPageTracker: () => pageTracker,
            getPropertyAppraisal: () => details.propertyAppraisal,
            shouldRenderBelowTheFold: () => hasFullDetails,
            getActiveExperiments: () => soldDetailsExperiments,
        };
        return soldDetailsGetters;
    } else {
        throw new Error(
            `SoldPropertyDetailsGetters error: type other than SoldResidentialListingDetails is not being handled (${data.value.details.__typename})`
        );
    }
};

export const SoldPropertyDetailsGetters = ({
    data,
    createTrackingData = mergeResidentialListingDetailsTrackingDataWithPage(),
    isClient = IS_CLIENT,
    children,
}: Props<SoldResidentialListingDetailsGetters>) => {
    const soldDetailsGetters = getSoldPropertyDetailsGetters({ data, createTrackingData, isClient });
    return children(soldDetailsGetters);
};

export const getBuyPropertyDetailsGetters = ({
    data,
    createTrackingData = mergeResidentialListingDetailsTrackingDataWithPage(),
    isClient = IS_CLIENT,
}: GetPropertyDetailsGetters) => {
    if (data.value.details.__typename === 'BuyResidentialListingDetails') {
        const details: BuyResidentialListingDetails = data.value.details;
        const hasFullDetails = shouldRenderBelowTheFold(data);
        const eventTracker = createTrackerFromListing(details.listing);
        const trackingData = isClient && hasFullDetails ? createTrackingData('buy', details.trackingData) : null;
        const pageTracker = hasFullDetails
            ? createPageTrackerFromListing('buy', details.trackingData)
            : { track: () => false };
        const buyDetailsGetters: BuyResidentialListingDetailsGetters = {
            getListing: () => details.listing,
            getMarketInsights: () => details.marketInsights,
            getRecentSales: () => details.recentSales,
            getRelatedProperties: () => details.relatedListings,
            getListingMetrics: () => details.listingMetrics,
            getEventTracker: () => eventTracker,
            getTrackingData: () => trackingData,
            getPageTracker: () => pageTracker,
            shouldRenderBelowTheFold: () => hasFullDetails,
            getActiveExperiments: () => buyDetailsExperiments,
            getAuction: () => details.auction,
            getInspections: () => details.inspections,
            getParentDisplaySuiteInspections: () => details.parentDisplaySuiteInspections,
        };
        return buyDetailsGetters;
    } else {
        throw new Error(
            `BuyPropertyDetailsGetters error: type other than BuyResidentialListingDetails is not being handled (${data.value.details.__typename})`
        );
    }
};

export const BuyPropertyDetailsGetters = ({
    data,
    createTrackingData = mergeResidentialListingDetailsTrackingDataWithPage(),
    isClient = IS_CLIENT,
    children,
}: Props<BuyResidentialListingDetailsGetters>) => {
    const buyDetailsGetters = getBuyPropertyDetailsGetters({ data, createTrackingData, isClient });
    return children(buyDetailsGetters);
};

export const getRentPropertyDetailsGetters = ({
    data,
    createTrackingData = mergeResidentialListingDetailsTrackingDataWithPage(),
    isClient = IS_CLIENT,
}: GetPropertyDetailsGetters) => {
    if (data.value.details.__typename === 'RentResidentialListingDetails') {
        const details: RentResidentialListingDetails = data.value.details;
        const hasFullDetails = shouldRenderBelowTheFold(data);
        const eventTracker = createTrackerFromListing(details.listing);
        const trackingData = isClient && hasFullDetails ? createTrackingData('rent', details.trackingData) : null;
        const pageTracker = hasFullDetails
            ? createPageTrackerFromListing('rent', details.trackingData)
            : { track: () => false };
        const rentDetailsGetters: RentResidentialListingDetailsGetters = {
            getListing: () => details.listing,
            getApplyOnline: () => details.applyOnline,
            getMarketInsights: () => details.marketInsights,
            getListingMetrics: () => details.listingMetrics,
            getRelatedProperties: () => details.relatedListings,
            getListingHiddenStatus: () => details.hiddenStatus,
            getEventTracker: () => eventTracker,
            getTrackingData: () => trackingData,
            getPageTracker: () => pageTracker,
            shouldRenderBelowTheFold: () => hasFullDetails,
            getActiveExperiments: () => rentDetailsExperiments,
            getInspections: () => details.inspections,
            getProperty: () => details.property,
        };
        return rentDetailsGetters;
    } else {
        throw new Error(
            `RentPropertyDetailsGetters error: type other than RentResidentialListingDetails is not being handled (${data.value.details.__typename})`
        );
    }
};
export const RentPropertyDetailsGetters = ({
    data,
    createTrackingData = mergeResidentialListingDetailsTrackingDataWithPage(),
    isClient = IS_CLIENT,
    children,
}: Props<RentResidentialListingDetailsGetters>) => {
    const rentDetailsGetters = getRentPropertyDetailsGetters({ data, createTrackingData, isClient });
    return children(rentDetailsGetters);
};
