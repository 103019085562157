/* @flow */
import type { BuyResidentialListing, SoldResidentialListing, RentResidentialListing } from '../../../../models/lexa';
import type { SchemaMarkup } from '../../../../models/meta-data/types';
import getResidenceSchemaMarkup from '../../../../models/meta-data/getResidenceSchemaMarkup';
import getAuctionSchemaMarkup from '../../../../models/meta-data/getAuctionSchemaMarkup';
import getInspectionSchemaMarkups from '../../../../models/meta-data/getInspectionSchemaMarkups';
import getBreadcrumbSchemaMarkup from '../../../../models/meta-data/getBreadcrumbSchemaMarkup';
import { getResidentialBreadcrumbItems } from '../../../../models/breadcrumb';

export default (listing: BuyResidentialListing, hostHeader: string): Array<SchemaMarkup> => {
    const items = getResidentialBreadcrumbItems('buy')(listing);
    return []
        .concat(getResidenceSchemaMarkup(listing))
        .concat(getAuctionSchemaMarkup(listing))
        .concat(getInspectionSchemaMarkups(listing))
        .concat(getBreadcrumbSchemaMarkup(hostHeader, items))
        .filter(Boolean);
};

export const getSoldSchemaMarkup = (listing: SoldResidentialListing, hostHeader: string): Array<SchemaMarkup> => {
    const items = getResidentialBreadcrumbItems('sold')(listing);
    return []
        .concat(getResidenceSchemaMarkup(listing))
        .concat(getBreadcrumbSchemaMarkup(hostHeader, items))
        .filter(Boolean);
};

export const getRentSchemaMarkup = (listing: RentResidentialListing, hostHeader: string): Array<SchemaMarkup> => {
    const items = getResidentialBreadcrumbItems('rent')(listing);
    return []
        .concat(getResidenceSchemaMarkup(listing))
        .concat(getInspectionSchemaMarkups(listing))
        .concat(getBreadcrumbSchemaMarkup(hostHeader, items))
        .filter(Boolean);
};
