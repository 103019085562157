/* @flow */
import type { LexaImage } from '../../../../models/lexa/lexaImageType';
import pluralize from './pluralize';

const formatMedia = (images: Array<LexaImage>): string => {
    const photoCount = images.length;
    const number = photoCount ? `${photoCount} ` : '';
    const unit = pluralize('photo', photoCount);
    return `${number}property ${unit}`;
};

export default formatMedia;
