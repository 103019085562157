import { compose3 as compose } from '../../../util/functional/typescript/compose';
import type { ResidentialListing, ResidentialDetailsGetters, GetResidentialListing } from '../../../../types';
import getFragmentData from '../../../util/typescript/getFragmentData';
import Description from './Description.graphql';

type Props = {
    eventTracker: () => void;
    title: string;
    description: string;
    className?: string;
};

type RequiredProps = {
    title: string;
    description: string;
};

type AdaptedData = {
    title: string;
    description: string;
};

const dataAdapter = ({ title, description }: RequiredProps): AdaptedData => {
    return {
        title,
        description,
    };
};

const getEventTracker = (data: ResidentialDetailsGetters) => ({
    eventTracker: data.getEventTracker().readMoreClick,
});

const getListingData = compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedData>(
    dataAdapter,
    getFragmentData(Description),
    (data) => data.getListing()
);

export default (data: ResidentialDetailsGetters): Props => ({ ...getListingData(data), ...getEventTracker(data) });
