/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import BrandingOnContactAgentPanelConfig from './BrandingOnContactAgentPanelConfig.graphql';
import type {
    GetResidentialListing,
    ResidentialListing,
    ResidentialListingViewConfiguration,
} from '../../../models/lexa';

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
};

type AdaptedProps = {|
    shouldRenderBranding: boolean,
|};

const dataAdapter = (listing: RequiredProps): AdaptedProps => {
    return { shouldRenderBranding: listing.viewConfiguration.details.agencyBrandingOnSidePanel };
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedProps>(
    dataAdapter,
    getFragmentData(BrandingOnContactAgentPanelConfig),
    (data: GetResidentialListing) => data.getListing()
);
