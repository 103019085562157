/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import { DEFAULT_DISPLAY_ADDRESS } from '../../../../models/Address';
import type { GetResidentialListing, ResidentialListing, Address } from '../../../../models/lexa';
import AddressFragment from './Address.graphql';

type RequiredProps = {
    address: Address,
};

type AdaptedProps = {
    address: string,
    suburb: string,
};

const dataAdapter = ({ address }: RequiredProps): AdaptedProps => ({
    suburb: `${address.suburb}, ${address.state} ${address.postcode}`,
    address: address.display.shortAddress || DEFAULT_DISPLAY_ADDRESS,
});

const adaptData = compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedProps>(
    dataAdapter,
    getFragmentData(AddressFragment),
    (data) => data.getListing()
);

export default adaptData;
