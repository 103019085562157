import { paletteHelper, spacingHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { WIDTH_CONSTRAIN } from '../../../styles/application/layout';

export const PersonalisedPropertyFeaturesContainer = styled.div`
    padding-top: ${spacingHelper('large')};
    padding-bottom: ${spacingHelper('medium')};
    background-color: ${paletteHelper('backgroundPrimary')};

    & > h2 {
        margin-bottom: ${spacingHelper('extraSmall')};
    }
`;

export const TopPropertyFeaturesItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${spacingHelper('extraSmall')};

    @media (max-width: ${WIDTH_CONSTRAIN}px) {
        grid-template-columns: 1fr;
    }
`;

export const TopPropertyFeatureItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${spacingHelper('medium')};
    padding-top: ${spacingHelper('small')};
    padding-bottom: ${spacingHelper('small')};

    p {
        flex-grow: 1;
    }
`;
