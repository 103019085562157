/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type {
    GetBuyResidentialListing,
    ResidentialListingViewConfiguration,
    ProjectProfile,
    BuyResidentialListing,
} from '../../../models/lexa';
import type { AdaptedProps } from '.';
import ParentProjectHeaderBranding from './ParentProjectHeaderBranding.graphql';

const PROJECT_HEADER_BRANDING_CID = 'pppdp|buy:pdp:header-logo';

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
    parent: ?ProjectProfile,
};

const adapter = ({ viewConfiguration, parent }: RequiredProps): ?AdaptedProps => {
    if (viewConfiguration.details.branding.header.size === 'LARGE' && parent) {
        return {
            backgroundColor: parent.branding.primaryColour,
            templatedUrl: parent.media.logo?.templatedUrl,
            altText: parent.name,
            link: parent._links.canonical.href.replace('{cid}', PROJECT_HEADER_BRANDING_CID),
        };
    } else {
        return null;
    }
};

export default compose<GetBuyResidentialListing, BuyResidentialListing, RequiredProps, ?AdaptedProps>(
    adapter,
    getFragmentData(ParentProjectHeaderBranding),
    (data) => data.getListing()
);
