/* @flow */
import React, { useContext } from 'react';
import View from './View';
import adaptListingCompanyData from './adaptListingCompanyData';
import type { GetResidentialListing } from '../../../models/lexa';
import ChannelContext from '../../../util/ChannelContext';

export type AdaptedProps = {
    backgroundColor: string,
    link: string,
    templatedUrl: ?string,
    altText: string,
};

type Props = {|
    data: GetResidentialListing,
|};

const HeaderBranding = ({ data }: Props) => {
    const channel = useContext(ChannelContext);
    const adaptedData = adaptListingCompanyData(channel)(data);
    return adaptedData ? <View {...adaptedData} /> : null;
};

export default HeaderBranding;

export { default as HeaderBrandingForChildListing } from './child-listing';
