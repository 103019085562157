/* @flow */
import React from 'react';
import type { ImageProps } from './type';

const View = ({ mainImage, profileLink }: ImageProps) => {
    return (
        <a className={`enquiry-main-image project-listing-company-context__main-image`} href={profileLink}>
            <img src={mainImage} />
        </a>
    );
};

export default View;
