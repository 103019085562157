/* @flow */
import './styles.scss';
import React, { Component } from 'react';
import { matches as defaultMatches } from '../../../client/device-capability/browserFeatures';
import appLogger from '../../../util/logging/appLogger';
import { TemplatedLazyImage } from '../../Image';
import { preloadTemplatedImage, type Preloader } from '../../../util/preloadTemplatedImage';

type Props = {
    openFirstPhoto: () => void,
    altText: string,
    templatedUrl: string,
    shouldLoadHighResImage: boolean,
    dependencies: {
        preloadImage: ({ templatedUrl: string, size: string }) => Preloader,
        matches: (string) => boolean,
    },
};

type State = {
    imageSize: string,
};

const HIGH_RES_IMAGE_SIZE = '1000x750';
const DEFAULT_IMAGE_SIZE = '800x600';
const HERO_HIGH_RES_POSTERBOARD_WIDTH_QUERY = '(min-width: 1600px)';

class HeroImage extends Component<Props, State> {
    preloader: Preloader;

    static defaultProps = {
        shouldLoadHighResImage: false,
        dependencies: {
            preloadImage: preloadTemplatedImage,
            matches: defaultMatches,
        },
    };

    state = { imageSize: DEFAULT_IMAGE_SIZE };

    handleKeyPress = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.props.openFirstPhoto();
        }
    };

    componentDidMount() {
        const matches = this.props.dependencies.matches;

        if (this.props.shouldLoadHighResImage && matches(HERO_HIGH_RES_POSTERBOARD_WIDTH_QUERY)) {
            this.loadImage({ templatedUrl: this.props.templatedUrl, size: HIGH_RES_IMAGE_SIZE });
        }
    }

    componentWillUnmount() {
        if (this.preloader) this.preloader.destroy();
    }

    imageLoaded = () => {
        this.setState({ imageSize: HIGH_RES_IMAGE_SIZE });
    };

    loadImage(image: { templatedUrl: string, size: string }) {
        this.preloader = this.props.dependencies.preloadImage(image);
        return this.preloader.promise.then(this.imageLoaded).catch(appLogger.error('failed to load hero image'));
    }

    render() {
        const { templatedUrl, altText } = this.props;
        return (
            <div
                role="button"
                className="hero-image"
                onClick={this.props.openFirstPhoto}
                tabIndex="0"
                onKeyPress={this.handleKeyPress}
            >
                <TemplatedLazyImage
                    width="800"
                    height="600"
                    templatedUrl={templatedUrl}
                    size={this.state.imageSize}
                    alt={altText}
                />
            </div>
        );
    }
}

export default HeroImage;
