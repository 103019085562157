/* @flow */
import React from 'react';
import View from '../../../branding/View';
import adaptLexaData from './adaptLexaData';
import type { GetBuyResidentialListing } from '../../../../models/lexa';

type Props = {
    data: GetBuyResidentialListing,
    className?: string,
};

const ContactAgentBrandingForChildListing = ({ data, className = '' }: Props) => {
    const adaptedData = adaptLexaData(data);
    if (!adaptedData) return null;
    const { brandingStyle, backgroundColor, templatedUrl, altText, brandingLink } = adaptedData;
    return (
        <View
            backgroundColor={backgroundColor}
            templatedUrl={templatedUrl}
            altText={altText}
            styleModifier={brandingStyle}
            brandingLink={brandingLink}
            className={`side-panel-branding ${className}`}
        />
    );
};

export default ContactAgentBrandingForChildListing;
