/* @flow */
import React, { useState } from 'react';
import HeroThumbnails from './hero-thumbnails';
import { SlideNames, type SlideName } from '../media-viewer';
import type { ResidentialDetailsGetters } from '../../models/lexa';
import type { Thumbnail } from './types';
import type { Element as ReactElement } from 'react';
import './styles.scss';
import { ResidentialMediaTypeBar } from './media-type-bar';
import HeroImage from './hero-image/View';
import { FeatureToggle } from '../toggles';

type Props = {|
    mainImageTemplatedUrl: string,
    openMediaViewer: (name: SlideName) => void,
    data: ResidentialDetailsGetters,
    altText: string,
    thumbnails: Array<Thumbnail>,
    pip: ReactElement<*>,
    posterBoard?: boolean,
    badgeContainer?: ReactElement<*>,
    renderMediaOverview: (onClose: () => void) => ReactElement<*>,
|};

const HeroView = ({
    mainImageTemplatedUrl,
    openMediaViewer,
    data,
    altText,
    thumbnails,
    pip,
    posterBoard = false,
    badgeContainer = null,
    renderMediaOverview,
}: Props) => {
    const [isMediaOverviewOpen, setIsMediaOverviewOpen] = useState(false);
    const hasThreeThumbnailsOrMore = thumbnails.length >= 3;

    const openFirstPhoto = () => openMediaViewer(SlideNames.FIRST);
    const openMediaOverview = () => setIsMediaOverviewOpen(true);
    const closeMediaOverview = () => setIsMediaOverviewOpen(false);
    const heroClass = posterBoard ? 'hero-poster' : 'hero';

    return (
        <FeatureToggle toggleName="mediaOverviewEnabled">
            {(isToggledOn) => (
                <div className={`${heroClass}`}>
                    <div
                        className={`${heroClass}__content ${
                            hasThreeThumbnailsOrMore ? `${heroClass}__content--has-thumbnails` : ''
                        }`}
                    >
                        {posterBoard ? <div className={`${heroClass}__pip`}>{pip}</div> : null}
                        <div className={`${heroClass}__mosaic`} data-testid="HeroMosaic">
                            <div className={`${heroClass}__primary-container`}>
                                <HeroImage
                                    openFirstPhoto={isToggledOn ? openMediaOverview : openFirstPhoto}
                                    altText={altText}
                                    templatedUrl={mainImageTemplatedUrl}
                                    shouldLoadHighResImage={posterBoard}
                                />
                                <div className={`${heroClass}__media-type-bar`}>
                                    <ResidentialMediaTypeBar data={data} openMediaViewer={openMediaViewer} />
                                </div>
                                {badgeContainer && (
                                    <div className={`${heroClass}__badge-container`}>{badgeContainer}</div>
                                )}
                            </div>
                            {hasThreeThumbnailsOrMore && (
                                <HeroThumbnails className={`${heroClass}__hero-thumbnails`} thumbnails={thumbnails} />
                            )}
                        </div>
                    </div>
                    {isMediaOverviewOpen && isToggledOn && renderMediaOverview(closeMediaOverview)}
                </div>
            )}
        </FeatureToggle>
    );
};

export default HeroView;
