import React from 'react';

import {
    SnowflakeLg,
    AlarmLg,
    BalconyLg,
    WifiLg,
    IntercomLg,
    DwellingSizeLg,
    WardrobeLg,
    AcademicCapLg,
    ShopLg,
    BusLg,
    CourtyardLg,
    DeckLg,
    DishwasherLg,
    DuctedCoolingLg,
    DuctedHeatingLg,
    ElectricHeatingLg,
    ElectricHotWaterLg,
    StarLg,
    EvaporativeCoolingLg,
    FloorboardsLg,
    FenceLg,
    CouchLg,
    GasLg,
    GasHotWaterLg,
    GreyWaterSystemLg,
    GymLg,
    HeatPumpLg,
    HydronicHeatingLg,
    SpaInsideLg,
    LandSizeLg,
    CarportLg,
    GarageLg,
    ToiletLg,
    CarSpaceOpenLg,
    FirePlaceLg,
    SunLg,
    SpaOutdoorLg,
    RemoteLg,
    DogLg,
    SwimmingPoolOvergroundLg,
    SwimmingPoolIngroundLg,
    RemoteGarageLg,
    AirconReverseLg,
    RumpusRoomLg,
    SecureParkingLg,
    ShedLg,
    ShowerLg,
    SolarHotWaterLg,
    SolarPanelsLg,
    SplitSystemCoolingLg,
    SplitSystemHeatingLg,
    DeskLg,
    TennisBallLg,
    DuctedVacuumLg,
    WaterTankLg,
    HammerLg,
} from '@rea-group/construct-kit-core';

interface IconsType {
    [key: string]: JSX.Element;
}

const Icons: IconsType = {
    airConditioning: <SnowflakeLg />,
    alarmSystem: <AlarmLg />,
    balcony: <BalconyLg />,
    broadband: <WifiLg />,
    buildingSize: <DwellingSizeLg />,
    builtInRobes: <WardrobeLg />,
    closeToSchools: <AcademicCapLg />,
    closeToShops: <ShopLg />,
    closeToTransport: <BusLg />,
    courtyard: <CourtyardLg />,
    deck: <DeckLg />,
    dishwasher: <DishwasherLg />,
    ductedCooling: <DuctedCoolingLg />,
    ductedHeating: <DuctedHeatingLg />,
    electricHeating: <ElectricHeatingLg />,
    electricHotWater: <ElectricHotWaterLg />,
    energyEfficiencyRating: <StarLg />,
    evaporativeCooling: <EvaporativeCoolingLg />,
    floorboards: <FloorboardsLg />,
    fullyFenced: <FenceLg />,
    furnished: <CouchLg />,
    gasHeating: <GasLg />,
    gasHotWater: <GasHotWaterLg />,
    greyWaterSystem: <GreyWaterSystemLg />,
    gym: <GymLg />,
    heatPumpHotWater: <HeatPumpLg />,
    hydronicHeating: <HydronicHeatingLg />,
    insideSpa: <SpaInsideLg />,
    intercom: <IntercomLg />,
    landSize: <LandSizeLg />,
    numCarportSpaces: <CarportLg />,
    numGarageSpaces: <GarageLg />,
    numLivingAreas: <CouchLg />,
    numToilets: <ToiletLg />,
    numOpenCarSpaces: <CarSpaceOpenLg />,
    openFireplace: <FirePlaceLg />,
    outdoorEnt: <SunLg />,
    outsideSpa: <SpaOutdoorLg />,
    payTV: <RemoteLg />,
    petFriendly: <DogLg />,
    poolAbove: <SwimmingPoolOvergroundLg />,
    poolInG: <SwimmingPoolIngroundLg />,
    remoteGarage: <RemoteGarageLg />,
    reverseCycleAirCon: <AirconReverseLg />,
    rumpusRoom: <RumpusRoomLg />,
    secureParking: <SecureParkingLg />,
    shed: <ShedLg />,
    numEnsuites: <ShowerLg />,
    solarHotWater: <SolarHotWaterLg />,
    solarPanels: <SolarPanelsLg />,
    splitSystemAirCon: <SplitSystemCoolingLg />,
    splitSystemHeating: <SplitSystemHeatingLg />,
    study: <DeskLg />,
    tennisCourt: <TennisBallLg />,
    vacuumSystem: <DuctedVacuumLg />,
    waterTank: <WaterTankLg />,
    workshop: <HammerLg />,
};

type Props = {
    id: string;
};
const PropertyFeaturesIcon: React.FC<Props> = ({ id }: Props) => {
    if (!Icons[id]) {
        return null;
    }
    return Icons[id];
};
export default PropertyFeaturesIcon;
