/* @flow */
import createView from './createView';
import adaptBuilderData from './builder-listing/adaptData';
import adaptProjectData from './project-listing/adaptData';
import adaptAgencyData from './agency-listing/adaptData';
import type { GetBuyResidentialListing, GetResidentialListing } from '../../../models/lexa';

export const BuilderTrafficDriver = createView<GetResidentialListing>(adaptBuilderData);
export const AgencyTrafficDriver = createView<GetResidentialListing>(adaptAgencyData);
export const ProjectTrafficDriver = createView<GetBuyResidentialListing>(adaptProjectData);
