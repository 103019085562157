/* @flow */

import { getProductDepthConfiguration } from '../../../../models/config/residential/ProductDepthConfiguration';
import type { GetBuyResidentialListing, BuyResidentialListing, ProjectProfile } from '../../../../models/lexa';
import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import ResidentialContactAgentBrandingForChildListing from './ResidentialContactAgentBrandingForChildListing.graphql';
import type { BrandingProps } from '../adaptLexaData';
import type { ProductDepth } from '../../../../models/config/residential/DepthTypes';

type RequiredProps = {
    productDepth: ProductDepth,
    parent: ?ProjectProfile,
};

const getBrandingInfo = (source, productDepth, brandingLink): ?BrandingProps => {
    if (!source.media.logo) return null;
    const backgroundColor = source.branding.primaryColour;
    const templatedUrl = source.media.logo.templatedUrl;
    const altText = source.name;
    const brandingStyle = getProductDepthConfiguration(productDepth).brandingStyle;
    return { brandingStyle, backgroundColor, templatedUrl, altText, brandingLink };
};

const dataAdapter = (listing: RequiredProps) => {
    const { parent } = listing;

    if (parent) {
        const brandingLink = parent._links.canonical.path;
        return getBrandingInfo(parent, listing.productDepth, brandingLink);
    }
};

export default compose<GetBuyResidentialListing, BuyResidentialListing, RequiredProps, ?BrandingProps>(
    dataAdapter,
    getFragmentData(ResidentialContactAgentBrandingForChildListing),
    (data: GetBuyResidentialListing) => data.getListing()
);
