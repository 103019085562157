/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type {
    GetResidentialListing,
    ResidentialListing,
    ResidentialListingViewConfiguration,
} from '../../../models/lexa';
import type { AdaptedData } from './types';
import PropertyInfoPosterBoard from './PropertyInfoPosterBoard.graphql';

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = ({ viewConfiguration }: RequiredProps): AdaptedData => {
    const { posterBoard } = viewConfiguration.details;

    return { shouldRenderPropertyInfo: !posterBoard };
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedData>(
    dataAdapter,
    getFragmentData(PropertyInfoPosterBoard),
    (data) => data.getListing()
);
