/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import ResidentialDetailsListingCache from '../../../screens/buy-residential-details/ResidentialDetailsListingCache.graphql';
import type { GetResidentialListing, ResidentialListing } from '../../../models/lexa';

const adaptLexaData = compose<GetResidentialListing, ResidentialListing, ResidentialListing, string>(
    (data) => data.id,
    getFragmentData(ResidentialDetailsListingCache),
    (data) => data.getListing()
);

export default adaptLexaData;
