import type { GetComponentGetters } from '../../../types';
import compose from '../../util/functional/typescript/compose';
import { PersonalisedPropertyFeatures, AdaptedProps } from './types';

const dataAdapter = ({ component }: { component: PersonalisedPropertyFeatures | null }): AdaptedProps | null => {
    return component;
};

const adaptLexaData = compose(dataAdapter, (data: GetComponentGetters) => {
    return { component: data.getComponent('PropertyFeatureComponent') };
});

export default adaptLexaData;
