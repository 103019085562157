/* @flow */
import React from 'react';
import adaptLexaData from './adaptLexaData';
import View from './View';
import type { ResidentialDetailsGetters } from '../../../models/lexa';
import type { AgentPhoneRevealPageSource } from '../../../client/tracking/event/types';

type Props = {
    data: ResidentialDetailsGetters,
    isHorizontal?: boolean,
    cid: string,
    revealPageSource: AgentPhoneRevealPageSource,
};

const AgentInfo = ({ data, isHorizontal, cid, revealPageSource }: Props) => (
    <View data={data} {...adaptLexaData(cid)(data)} isHorizontal={isHorizontal} revealPageSource={revealPageSource} />
);

export default AgentInfo;
