/* @flow */
import React, { useContext, type Node, type ComponentType } from 'react';
import type { ResidentialDetailsGetters } from '../../../models/lexa';
import AgentInfo from '../agent-info';
import adaptLexaData from './adaptLexaData';
import './styles.scss';
import RenderBelowTheFold from '../../RenderBelowTheFold';
import ChannelContext from '../../../util/ChannelContext';

type Props = {|
    data: ResidentialDetailsGetters,
    BrandingComponent: ComponentType<*>,
    SidebarTrafficDriverComponent: ComponentType<*>,
    buttons: Node,
    footer?: Node,
|};

const View = ({ data, BrandingComponent, SidebarTrafficDriverComponent, buttons, footer }: Props) => {
    const { shouldRenderBranding } = adaptLexaData(data);
    const channel = useContext(ChannelContext);

    return (
        <div className="contact-agent-panel">
            {shouldRenderBranding && (
                <BrandingComponent
                    data={data}
                    className=""
                    cid={`agency-profile-page|${channel}:pdp:contact-side-panel&sourcePage=${channel}:property-details&sourceElement=agency-branding`}
                />
            )}
            <AgentInfo
                data={data}
                cid={`agent-profile-page|${channel}:pdp:contact-side-panel`}
                revealPageSource="contact side panel"
            />
            <SidebarTrafficDriverComponent
                data={data}
                className="contact-agent-panel__traffic-driver"
                cidFrom={`${channel}:pdp:contact-side-panel&sourcePage=${channel}:property-details&sourceElement=agency-branding`}
            />
            <RenderBelowTheFold data={data}>
                <div className="contact-agent-panel__buttons">{buttons}</div>
            </RenderBelowTheFold>
            {footer}
        </div>
    );
};

export default View;
