/* @flow */

import React from 'react';
import View from '../View';
import adaptParentProjectData from './adaptData';
import type { GetBuyResidentialListing } from '../../../../models/lexa';
import type { Cid } from '../type';

type Props = {
    data: GetBuyResidentialListing,
    className?: string,
    cidFrom: Cid,
};

const SidebarTrafficDriverForChildListing = ({ data, cidFrom, className = '' }: Props) => {
    const adaptedData = adaptParentProjectData(cidFrom)(data);
    return adaptedData ? <View {...adaptedData} className={className} /> : null;
};

export default SidebarTrafficDriverForChildListing;
