/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import type { ProjectProfile, GetBuyResidentialListing } from '../../../../models/lexa';
import type { InfoProps } from '../type';
import getFragmentData from '../../../../util/getFragmentData';
import ParentProjectInfo from './ParentProjectInfo.graphql';

type Props = {
    parent: ?ProjectProfile,
};

const dataAdapter =
    (cidFrom: string) =>
    ({ parent }: Props): ?InfoProps => {
        const cid = `pppdp|${cidFrom}`;
        if (parent) {
            const propertiesNumber = parent.childListings.totalCount > 1 ? `${parent.childListings.totalCount} ` : '';

            return {
                name: parent.name,
                detailInfo: `Explore ${propertiesNumber}properties in ${parent.name}`,
                profileLink: `${parent._links.canonical.path}?cid=${cid}`,
            };
        }
        return null;
    };

export default (cidFrom: string): ((GetBuyResidentialListing) => ?InfoProps) =>
    compose(dataAdapter(cidFrom), getFragmentData(ParentProjectInfo), (data: GetBuyResidentialListing) =>
        data.getListing()
    );
