/* @flow */
import type { Channel } from '../../../../models/Channel';
import type { ResidentialListing } from '../../../../models/lexa';

const buyTitle = ({
    id,
    propertyType,
    address: {
        display: { fullAddress },
        suburb,
    },
}): string => {
    if (fullAddress) {
        return `${fullAddress} - ${propertyType.display} for Sale - realestate.com.au`;
    }
    return `${suburb} - address available on request - ${propertyType.display} for Sale ${id} - realestate.com.au`;
};

const rentTitle = ({
    id,
    propertyType,
    address: {
        display: { fullAddress },
        suburb,
    },
}): string => {
    if (fullAddress) {
        return `${fullAddress} - ${propertyType.display} for Rent - realestate.com.au`;
    }
    return `${suburb} - address available on request - ${propertyType.display} for Rent ${id} - realestate.com.au`;
};

const soldTitle = ({
    id,
    address: {
        suburb,
        state,
        postcode,
        display: { fullAddress },
    },
}): string => {
    const propertyAddress = fullAddress ? fullAddress : `Property ${id}, ${suburb}, ${state} ${postcode}`;
    return `${propertyAddress} - Property Details`;
};

const titleTemplates = {
    buy: buyTitle,
    sold: soldTitle,
    rent: rentTitle,
};

export default (channel: Channel) =>
    ({ id, propertyType, address }: ResidentialListing): string => {
        return titleTemplates[channel]({ id, propertyType, address });
    };
